.justify-content {
  justify-content: center;
}

.app-store {
  margin-left: 0px;
}

.home-page-carousel-container {
  padding-top: 10px;
  padding-bottom: 40px;
}

.home-page-banner-box {
  border-radius: 15px;
  height: 180px;
  width: auto;
  background-color: #222231;
}

.home-page-box-absolute {
  position: relative;
  margin-top: -90px;
  z-index: 1;
}

.tempah-sekarang {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  text-transform: none;
  padding: 0;
  font-family: Rubik, sans-serif;
  /* background-color: 'red' !important; */
}

.tempah-sekarang-container {
  align-self: flex-end;
}

.submit-register {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  text-transform: none;
  padding: 0;
  background-color: #153460;
  /* background-color: 'red' !important; */
}

/* .home-page-radio-box:before {
    width: 100%;
    height: 100px;
    background-color: aquamarine;
    content: "";
    display: inline-block;
} */

/* input[type=radio].home-page-radio:before {
    content: "";
    display: inline-block;
    width: 100px;
    height: 100px;
    background:blueviolet;
    opacity: 1!;
}
input[type=radio].home-page-radio:checked:before {
    background: blue;
    opacity: 1;
} */

label.radio {
  cursor: pointer;
  width: 100%;
}

label.radio input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
}

label.radio span {
  padding: 12px 14px;
  width: 100%;
  height: 120px;
  text-align: center;
  border: 1px solid black;
  display: inline-block;
  color: var(--thm-secondary);
  border-radius: 5px;
}

label.radio input:checked + span {
  border: 2px solid var(--thm-border-checkbox);
  background-color: var(--thm-blue-checkbox);
}

.radio-img {
  height: 100%;
}

.registration-container {
  border-radius: 15px;
  background-color: white;
  padding: 32px;
}

.blue-background {
  background-image: url("../img/nasty_fabric.png");
  background-repeat: repeat;

  /* background-color: #0c3b54; */
}

.profile-box-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: var(--thm-yellow-header);
  padding: 16px;
}

.profile-box-bottom {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: white;
  padding: 16px;
}

.profile-box-underlined {
  text-decoration: underline;
}

.profile-text-right {
  text-align: right;
}

.profile-text-centre {
  text-align: center;
}

.profile-text-left {
  text-align: left;
}

.profile-multiple-text {
  padding-top: 4px;
  padding-bottom: 4px;
}

.blue-text {
  color: var(--thm-thirtiary);
}

.dark-blue-text {
  color: var(--thm-forth);
}

.header-blue-text {
  color: var(--thm-blue-header) !important;
}

.header-yellow-text {
  color: var(--thm-yellow-header);
}

.sigma_btn {
  border-radius: 5px;
}
.white-text {
  color: white;
}

.black-text {
  color: black;
}

.bold-text {
  font-weight: bold;
}

.downloadIcon {
  width: 20px;
  height: 20px;
}

.confirmation-box {
  border-radius: 15px;
  background-color: white;
  padding: 30px;
}
.location-icon {
  position: absolute;
  left: 12px;
  margin-top: -40px;
}
.location-icon-1 {
  position: absolute;
  left: 26px;
  top: 14px;
}
.confirmation-vehicle-box {
  border: 1px solid black;
  border-radius: 15px;
  padding: 16px;
}

.confirmation-vehicle {
  width: 100%;
}

.padding-pengesahan-top {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}
.track-address-box {
  background-color: #0c3b54;
  border-radius: 15px;
  padding: 16px;
}
.padding-track {
  padding: 26px;
}
.no-padding-no-margin {
  padding: 0px;
  margin: 0px;
}
.padding-pengesahan-bottom {
  padding-bottom: 16px;
}

.menu-extra {
  width: 250px;
}

.menu-daftar {
  background-color: white;
  /* border: 1px solid var(--thm-secondary); */
  width: 100%;
  height: 50px;
  border-radius: 5px;
  text-transform: none;
  padding: 0;
}

.daftar-text {
  color: var(--thm-secondary);
}

.daftar-text:focus {
  color: var(--thm-secondary);
}

.menu-logmasuk {
  background-color: var(--thm-base);
  width: 100%;
  height: 50px;
  border-radius: 5px;
  text-transform: none;
  padding: 0;
}
.menu-username {
  width: max-content;
}
.logmasuk-text {
  color: white;
}

.logo-login {
  width: 30%;
  height: auto;
}

.padding-register {
  padding-top: 32px;
  padding-bottom: 16px;
}

/* must use this specific name to change modal body css */
/* .modal-content { 
    border-radius: 15px;
    height: auto;
} */

.text-transform-none {
  text-transform: none;
}

.light-background {
  background-color: rgb(229, 230, 231);
}

.white-background {
  background-color: white;
}

.pesanan-box {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  color: white;
  /* text-align: center; */
}

.home-form {
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  background-color: white;
  /* border-radius: 10px; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2); /* for new form header */
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */
  margin-bottom: 64px;
}

.home-checkbox {
  border: 1px solid rgb(229, 230, 231);
  border-radius: 10px;
  padding-top: 8px;
  margin-top: 16px;
}

.home-services {
  background-color: var(--thm-secondary);
  padding-top: 32px;
  padding-bottom: 24px;
}

.home-services-box {
  background-color: white;
  border-radius: 10px;
  height: 450px;
}

.news-img {
  width: 100%;
}

.cancel-profile {
  background-color: gray;
}

.centre-blue-edit-text {
  align-self: center;
}
.align-right {
  position: relative;
  right: 30px;
  width: 0%;
}
/* */
.expandedSpace {
  height: 160vh;
  /* background-color: green; */
}

.expanded-home {
  height: 170vh;
}

.normalSpace {
  height: 0;
  display: none;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

/* input:disabled.disabled-colour {
    background-color: 'white' !important
} */

.validation-msg-error {
  color: red;
}

.validation-msg-passed {
  color: green;
}

button.disabled-btn {
  background-color: grey;
}

button.enabled-btn {
  background-color: var(--thm-base);
}

button.enabled-order {
  background-color: red;
}

.margin-picker-container {
  margin-bottom: 24px;
}

.delivery-location-container {
  margin-bottom: 8px;
}

label.fixed-topup {
  cursor: pointer;
  width: 100%;
}

label.fixed-topup input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
}

label.fixed-topup span {
  padding-top: 4px;
  width: 100%;
  height: 40px;
  text-align: center;
  border: 1px solid black;
  display: inline-block;
  color: var(--thm-secondary);
  border-radius: 5px;
  text-transform: uppercase;
}

label.fixed-topup input:checked + span {
  border: 2.5px solid var(--thm-secondary);
  background-color: lightblue;
}

.reg-extras {
  display: block;
  background-color: #fff;
  border: 1px solid var(--thm-border);
  text-align: center;
  transition: 0.3s;
  margin-bottom: 30px;
  color: var(--thm-b-text);
  position: relative;
}

label.reg-user-types,
label.reg-user-types2,
label.reg-user-types3 {
  cursor: pointer;
  width: 100%;
}

label.reg-user-types input,
label.reg-user-types2 input,
label.reg-user-types3 input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
}

label.reg-user-types span {
  padding-top: 4px;
  width: 100%;
  height: 40px;
  text-align: center;
  border: 1px solid black;
  display: inline-block;
  color: var(--thm-secondary);
  /* border-radius: 5px; */
  text-transform: uppercase;
}

label.reg-user-types2 span {
  padding-top: 4px;
  width: 100%;
  height: 40px;
  text-align: center;
  border: 1px solid black;
  display: inline-block;
  color: var(--thm-secondary);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  text-transform: uppercase;
}

label.reg-user-types3 span {
  padding-top: 4px;
  width: 100%;
  height: 40px;
  text-align: center;
  border: 1px solid black;
  display: inline-block;
  color: var(--thm-secondary);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-transform: uppercase;
}

label.reg-user-types input:checked + span,
label.reg-user-types2 input:checked + span,
label.reg-user-types3 input:checked + span {
  border: 1px solid var(--thm-secondary);
  background-color: lightblue;
}

.padding-horizontal-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.padding-horizontal-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.home-service-logo {
  height: 130px;
}

.footer-logo {
  width: 100%;
}

.header-logo {
  height: 100px;
}

.footer-wave {
  width: 100%;
  /* height: 100%; */
  position: relative;
  /* top: -200px */
}

.shadow-header {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Firefox 3.5 - 3.6 */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.border-footer {
  border: 1px solid black;
}

.hyperlink-text {
  color: var(--thm-base);
}

.justify-privacy-text {
  text-align: justify;
  text-justify: inter-word;
}

.yellow-footer {
  background-color: var(--thm-yellow-footer);
}

.blue-footer {
  color: white;
}

.footer-icons-img {
  width: 25px;
  height: auto;
}

label.reg-types-box {
  cursor: pointer;
  width: 100%;
}

label.reg-types-box input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
}

label.reg-types-box span {
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  height: 110px;
  text-align: center;
  border: 1px solid var(--thm-border-ori-checkbox);
  display: inline-block;
  color: var(--thm-checkbox);
  border-radius: 5px;
  text-transform: uppercase;
}

label.reg-types-box input:checked + span {
  border: 2px solid var(--thm-border-checkbox);
  background-color: var(--thm-blue-checkbox);
}

.reg-tabs-desc-line {
  background-color: white;
  height: 5px;
  margin-bottom: 20px;
}

.tabs-desc-spacing {
  margin-top: 40px;
  margin-left: 30px;
}

.reg-user-background {
  background-color: #006c9a;
  padding-bottom: 80px;
}

.reg-driver-background {
  background-color: #114c74;
  padding-bottom: 80px;
}

.reg-vendor-background {
  background-color: #bc0055;
  padding-bottom: 80px;
}
.reg-agent-background {
  background-color: #00a144;
  padding-bottom: 80px;
}

.success-reg-box {
  border-radius: 10px;
  background-color: var(--thm-base);
  color: white;
  /* text-align: center; */
}

.hide-stuff {
  display: none;
}

.show-stuff {
  display: inline;
}

.drop {
  border-radius: 4px;
  /* border: 1px solid #e1e1e1 !important; */
  width: 100%;
  background-color: #f4f5f8;
}

.drop:focus {
  border: 2px solid var(--thm-base) !important;
}

.reg-date {
  width: 100%;
}

input[type="file"]#file-upload-button {
  border-radius: 5px !important;
  background-color: green;
}

.padding-top-bottom-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.side-regist-container {
  padding-top: 24px;
  padding-bottom: 24px;
  color: white;
  text-align: center;
  cursor: pointer;
}

.side-regist-container-selected {
  padding-top: 24px;
  padding-bottom: 24px;
  color: white;
  background-color: var(--thm-blue-header);
  text-align: center;
  cursor: pointer;
}

.background-grey {
  background-color: var(--thm-grey-background);
}

.extra-profile-box {
  border-radius: 15px;
  padding: 16px;
}

.border-radius-15 {
  border-radius: 15px;
}

.referral-box-outer {
  border-radius: 15px;
  border: 1px solid gray;
  background-color: white;
  padding: 12px;
}

.referral-box-inner {
  border-radius: 10px;
  border: 1px solid gray;
  background-color: var(--thm-grey-background);
  height: 40px;
  justify-content: center;
}

.cursor {
  cursor: pointer;
}

.min-page-height {
  min-height: 100vh;
}

.font14 {
  font-size: 14px;
}

/* google map */
.main-wrapper {
  height: 100%;
  margin: 10px 50px;
  filter: drop-shadow(-1px 5px 3px #ccc);
}
.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}
/* -------- */

.line-confirm {
  height: 1px;
  width: 100%;
  background-color: var(--thm-border);
}

.upload-file-btn {
  height: 30px;
  /* border: 1px solid rgb(136, 135, 135); */
  border-radius: 8px;
  background-color: var(--thm-base);
}

.plain-text-area {
  background-color: transparent;
  border: none;
}

.pad-horizontal-30 {
  padding: 30px;
}

.pad-vertical-60 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* pagination */
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: var(--thm-yellow-header);
  border-color: var(--thm-yellow-header);
  color: #fff;
}
.pagination > li > a {
  border: 1px solid var(--thm-yellow-header);
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--thm-yellow-header);
  border-color: var(--thm-yellow-header);
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: var(--thm-yellow-header);
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

/* ======== */

.home-our-services {
  background-color: var(--thm-base);
}

/* ========= */
/* mobile responsive */

.home-form-spacing {
  margin-left: 70px;
  margin-right: 70px;
  padding-top: 50px;
}

.home-earn-extra {
  display: inline-block;
  align-self: center;
  width: 50%;
  margin-bottom: 0;
}

.home-services-spacing {
  margin-left: 180px;
  margin-right: 180px;
  margin-bottom: 100px;
}

.home-form-header {
  background-image: url("../img/form-header.png");
  background-size: cover;
  background-position: center;
  display: flex;
  padding: 70px;
}

.profile-spacing {
  /* margin-left: 0;
      margin-right: 0; */
}

.home-services-box > .sigma_service-body > p {
  font-size: 14px;
}

.burger-icon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: white;
  cursor: pointer;
  padding-top: 12px;
}

.reg-slider {
  width: 15vw !important;
  height: 'auto'
}

.contact-us-pad {
  padding-top: 8px;
  padding-bottom: 8px;
}

.capitalize-all {
  text-transform: uppercase;
}

.height-inside-banner {
  height: 80vh;
}

.text-banner-spacing {
  margin-left: 5vw;
}

.font-heavy {
  font-weight: 900;
}

.background-white {
  background-color: white !important;
}

.yellow-line {
  width: 100px;
  height: 3px;
  background-color: #ffcd3d;
  margin: auto;
}

.regist-title {
  font-weight: bold;
  text-align: left;
}

.logo-regist {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 180px;
}

.login-modal {
  width: 40vw; /* Occupy the 50% of the screen width */
  max-width: 50vw;
}

.bck button {
  background-color: transparent;
  border-radius: 15px;
  width: 100%;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.bck button .faq-question {
    text-align: left;
    white-space: normal;
}

.plusImg {
  display: block;
}

.minusImg {
  display: none;
}

button[aria-expanded="true"] .faq-question .faq-plus-wrap .plusImg{
  display: none;
}

button[aria-expanded="true"] .faq-question .faq-plus-wrap .minusImg{
  display: block;
}

/* *******  */
@media screen and (min-width: 1920px) {
  .submit-register {
      width: 90%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .home-form-spacing {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
  }

  .home-earn-extra {
    display: inline-block;
    align-self: center;
    width: 100%;
    margin-bottom: 0;
  }

  .home-services-spacing {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .home-form-header {
    background-image: url("../img/form-header.png");
    background-size: cover;
    background-position: center;
    display: flex;
    padding: 30px;
  }

  .profile-spacing {
    /* margin-left: 10px;
        margin-right: 10px; */
  }

  .home-services-box > .sigma_service-body > p {
    font-size: 14px;
  }

  .navbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    /* background-color: var(--thm-yellow-header); */
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: var(--thm-blue-header);
    border-radius: 0;
  }

  .login-modal {
    width: 90vw; 
    margin-left: auto;
    margin-right: auto;
  }

}

@media screen and (max-width: 479px) {
  .reg-slider {
    width: 40vw !important;
  }
}
/* ========= */