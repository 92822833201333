.w-embed-youtubevideo {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/youtube-placeholder.2b05e7d68d.svg');
  background-size: cover;
  background-position: 50% 50%;
}

.w-embed-youtubevideo:empty {
  min-height: 75px;
  padding-bottom: 56.25%;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}
/* 16px = 1em */
h1 {
  position: static;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 10px;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  color: #fff;
  font-size: 1.5625em;
  /* font-size: 25px; */
  line-height: 44px;
  font-weight: 700;
  text-shadow: 0 0 10px #000;
}

h2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  color: #285671;
  font-size: 2em;
  /* font-size: 32px; */
  line-height: 36px;
  font-weight: 700;
  text-shadow:  rgb(0 0 0 / 25%) 0px 3px 3px;
}

h2.banner-txt {
  font-size: 2.75em;
  font-weight: 900;
}

h3 {
  font-family: Rubik, sans-serif;
  font-size: 1.5em;
  line-height: 36px;
  font-weight: 700;
  text-shadow:  rgb(0 0 0 / 25%) 0px 3px 3px;
}

@media screen and (min-width: 2000px) {
  .extra {
    width: 50vh !important;
  }
}

/* On screens that are 992px wide or less, */
@media screen and (max-width: 992px) {
  h3 {
    font-family: Rubik, sans-serif;
    font-size: 1.2em;
    line-height: 30px;
  }
}

/* On screens that are 600px wide or less, */
@media screen and (max-width: 600px) {
  h3 {
    font-family: Rubik, sans-serif;
    font-size: 1em;
    line-height: 25px;
  }
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  font-size: 1.125em;
  /* font-size: 18px; */
  line-height: 24px;
  font-weight: 700;
  text-align: center;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  font-size: 0.875em;
  /* font-size: 14px; */
  line-height: 20px;
  font-weight: 700;
}

a {
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  font-weight: 700;
  text-decoration: underline;
}
/* */
span {
  font-family: Rubik, sans-serif;
}

p {
  font-family: Rubik, sans-serif;
}

label {
  font-family: Rubik, sans-serif;
}
/* */
.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: 80%;
  margin-top: 19px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.container.navbar {
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.container.with-paragraph {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section {
  /* height: 80vh; */
}

.div-block {
  display: none;
  padding-top: 152px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 233px;
  height: 65px;
  margin-top: 19px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  background-image: url('../img/images/button_tapaw.svg');
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-attachment: scroll;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.43);
}

.button:hover {
  font-size: 20px;
}

.button.registration {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.heading {
  display: block;
}

.heading.second-slider {
  font-size: 17px;
  line-height: 31px;
}

.heading.third-slider {
  font-size: 17px;
}

.div-block-2 {
  position: relative;
  z-index: 1001;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  clear: none;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 7px;
  background-color: #f2f2f2;
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.25);
}

.section-2 {
   /* margin-top: -40px; */
   /* margin-top: -12vh; */
   margin-top: -6.1vh;
  /* padding-top: 0px; */
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
}

.div-block-3:hover {
  background-color: #fff;
}

.heading-2 {
  margin-bottom: 0px;
  text-transform: uppercase;
}

/* .slider {
  height: 100%;
}

.slider.next {
  background-image: url('../img/images/tapaw-header4.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.slide-nav {
  display: none;
}

.slide {
  overflow: hidden;
  background-image: url('../img/images/tapaw-header3..jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.slide.second {
  overflow: visible;
  background-image: url('../img/images/tapaw-header2.jpg.jpg'), url('../img/images/tapaw-header1.jpg');
  background-position: 50% 50%, 0px 0px;
  background-size: cover, cover;
}

.slide.third {
  overflow: visible;
  background-image: url('../img/images/tapaw-header3..jpg'), url('../img/images/tapaw-header1.jpg');
  background-position: 0px 0px, 0px 0px;
  background-size: cover, auto;
  -o-object-fit: fill;
  object-fit: fill;
}

.slide.first {
  background-image: url('../img/images/tapaw-header1.jpg');
}

.slide.fourth {
  background-image: url('../img/images/tapaw-header4.jpg');
} */

.section-3 {
  margin-top: 5%;
  background-color: #fff;
}

.columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-2 {
  height: 100%;
}

.list-feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image.icons {
  max-width: none;
  min-width: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.div-feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-block {
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  font-weight: 500;
}

.heading-3 {
  color: #2b5973;
  font-weight: 800;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.image-2 {
  border-radius: 10px;
}

.heading-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.heading-4.tapaw-feature {
  margin-top: 20px;
  color: #56b6e6;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 3px;
  margin-top: 5px;
  margin-bottom: 25px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: #ffcd3d;
  text-align: left;
}

.heading-5 {
  margin-top: 40px;
  /* margin-top: 60px; */
}

.navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #faa616;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8941e), to(#fec60d));
  background-image: linear-gradient(180deg, #f8941e, #fec60d);
}

.image-3 {
  display: block;
  max-width: 40%;
  min-width: 120px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.brand {
  height: 100%;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.register {
  margin-top: 11px;
}

.nav-button {
  margin: 20px 10px;
  /* padding: 10px 40px; */
  padding: 10px 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: #153460;
  border-radius: 5px;
  background-color: transparent;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  color: #153460;
  font-size: 100%;
  font-weight: 700;
  text-align: left;
}

.nav-button:hover {
  background-color: #153460;
  color: #fff;
}

.nav-button.sign-in {
  margin-right: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 5px;
  background-color: #fff;
  font-size: 100%;
}

.nav-button.sign-in:hover {
  border-color: #153460;
  background-color: #153460;
  color: #fff;
}

.nav-link {
  padding-right: 10px;
  padding-left: 10px;
  color: #153460;
  font-size: 12px;
  text-transform: uppercase;
}

.nav-link:hover {
  color: #fff;
  font-size: 13px;
}

.gradient-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: auto;
  height: 175%;
  margin: auto;
}

.carousel-arrow-oval {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 3;
  width: 3.25em;
  height: 3.25em;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
  background-color: #ff7a00;
}

.carousel-arrow-oval.next-arrow {
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.heading-6 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #000;
  font-size: 5.5em;
  line-height: 1;
  font-weight: 400;
  text-align: center;
}

.text-f-15 {
  font-family: Inconsolata, monospace;
  color: #000;
  font-size: 1.2em;
  line-height: 1;
  font-weight: 400;
  white-space: nowrap;
}

.text-f-15.light-grey-font {
  color: rgba(0, 0, 0, 0.5);
}

.text-f-15.grey-font {
  color: rgba(0, 0, 0, 0.75);
}

.header {
  margin-top: 8em;
  margin-bottom: 2em;
}

.text-f-13 {
  font-family: Oswald, sans-serif;
  color: #000;
  font-size: 1.05em;
  line-height: 1;
  font-weight: 300;
  white-space: nowrap;
}

.text-f-13.all-caps {
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.text-f-13.all-caps.light-grey-font {
  color: hsla(0, 0%, 100%, 0.6);
}

.carousel-arrow {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.carousel-arrow.arrow-next {
  padding-right: 1em;
  padding-left: 0em;
}

.heading-7 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 2.75em;
  line-height: 1;
  font-weight: 400;
  white-space: nowrap;
}

.credentials {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2em;
  padding-right: 3em;
  padding-bottom: 2em;
  padding-left: 3em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.location {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content {
  overflow: hidden;
}

.carousel-arrow-icon {
  position: relative;
  z-index: 4;
  width: auto;
  height: 1.25em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.swiper-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.9;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  color: hsla(0, 0%, 100%, 0.5);
}

.slider-2 {
  position: relative;
  overflow: visible;
  margin-bottom: 4em;
}

.carousel-arrows {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  justify-items: end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-f-14 {
  font-family: Oswald, sans-serif;
  color: #878787;
  font-size: 1.1em;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 0.25em;
  text-transform: uppercase;
}

.slide-content {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 3em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slide-img-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), color-stop(65%, rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.75)));
  background-image: linear-gradient(180deg, transparent, transparent 50%, rgba(0, 0, 0, 0.5) 65%, rgba(0, 0, 0, 0.75));
}

.slide-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.credential {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.slide-img {
  z-index: 0;
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.text-f-14-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dot-divider {
  width: 0.25em;
  height: 0.25em;
  margin-right: 0.75em;
  margin-left: 0.75em;
  border-radius: 100%;
  background-color: #ff7a00;
}

.swiper-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.swiper-wrapper.first-swiper-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.swiper.first-swiper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.divider {
  width: 4em;
  height: 1px;
  margin-right: 1.5em;
  background-color: #878787;
}

.divider.left-margin {
  margin-right: 0em;
  margin-left: 1.2em;
}

.slide-divider {
  width: 4em;
  height: 0.1em;
  margin-top: 2em;
  background-color: #ff7a00;
}

.carousel-arrow-w {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
}

.carousel-arrow-w.arrow-next {
  margin-right: 2em;
}

.carousel-arrow-w.arrow-previous {
  margin-left: 2em;
}

.div-block-5 {
  display: -ms-grid;
  display: grid;
  margin-top: 5%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-tapaw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 253px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-image: url('../img/images/6-photo.f6de640f.jpg');
  background-position: 0px 0px;
  background-size: auto;
  opacity: 1;
}

/* ---edited *-- */
.div-tapaw.tm1 {
  background-image: url('../img/products/TapawMall.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.div-tapaw.tm2 {
  background-image: url('../img/products/TapawPharmacy.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.div-tapaw.tm3 {
  background-image: url('../img/products/TapawPet.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.div-tapaw.tm4 {
  background-image: url('../img/products/TapawMart.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.div-tapaw.tm5 {
  background-image: url('../img/products/TapawFresh.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.div-tapaw.tm6 {
  background-image: url('../img/products/TapawGadget.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.div-tapaw.tm7 {
  background-image: url('../img/products/Tapawbeauty.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.div-tapaw.tm8 {
  background-image: url('../img/products/TapawFood.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
/* -------- */

.div-tapaw.second {
  background-image: url('../img/images/nathan-dumlao-bRdRUUtbxO0-unsplash.png');
  background-size: cover;
}

.div-tapaw.third {
  background-image: url('../img/images/nathan-dumlao-bRdRUUtbxO0-unsplash.png');
  background-size: cover;
}

.heading-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-image: url('../img/images/jeshoots-com-nREv5-El0M4-unsplash.png');
  background-position: 50% 50%;
  background-size: cover;
  color: #fff;
  text-align: center;
}

.heading-8.two {
  background-image: url('../img/images/fikri-rasyid-ezeC8-clZSs-unsplash.png');
  background-size: cover;
}

.heading-8.three {
  background-image: url('../img/images/siriwan-arunsiriwattana-gs0coXLmjdI-unsplash.png');
}

.heading-8.four {
  background-image: url('../img/images/nathan-dumlao-bRdRUUtbxO0-unsplash.png');
}

.heading-8.five {
  background-image: url('../img/images/lily-banse--YHSwy6uqvk-unsplash.png');
}

.section-5 {
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
}

.footer {
  padding-top: 60px;
  padding-bottom: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #e9b500;
  text-align: center;
}

.footer-flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -82px;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
}

.footer-logo-link {
  display: block;
  max-height: 60px;
  max-width: 300px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.footer-image {
  width: 83%;
  height: 300%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.footer-heading {
  margin-top: 0px;
  margin-bottom: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 900;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.footer-link {
  display: block;
  margin-bottom: 10px;
  color: #444;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.image-5 {
  margin-top: 5%;
}

.slider-button {
  opacity: 0.3;
}

.slider-button:hover {
  opacity: 1;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../img/images/reg-user.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

/* for driver */
.div-block-8.driver {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../img/images/reg-driver.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

/* for vendor */
.div-block-8.vendor {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../img/images/reg-agent.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

/* for agent */
.div-block-8.agent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../img/images/agent-background-regist.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.div-block-8.connect {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../img/images/api-bg.png');
  background-position: 50% 50%;
  background-size: cover;
}

.columns-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.columns-2.driver {
  background-image: url('../img/images/reg-driver.jpg');
}

.columns-2.vendor {
  background-image: url('../img/images/reg-agent.jpg');
}

.div-block-9 {
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 #ccc;
}

.form {
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  font-family: Rubik, sans-serif;
  /* font-family: Montserrat, sans-serif; */
}

.form:focus {
  box-shadow: 0 0 3px 1px #57f;
}

.form.no-edit {
  background-color: #ececec;
}

.field-label {
  margin-left: 30px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  font-size: 12px;
}

.field-label.secondary {
  margin-bottom: 10px;
  margin-left: 0px;
  padding-top: 20px;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
}

.column-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-bottom: 44px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.column-4 {
  height: 100%;
}

.div-block-10 {
  height: 50px;
}

.div-block-11 {
  width: 90%;
  margin-top: 10px;
}

.checkbox-label {
  font-family: Rubik, sans-serif;
  /* font-family: Montserrat, sans-serif; */
}

.bold-text {
  color: #ffcd04;
  text-decoration: underline;
}

.checkbox-field {
  margin-top: 10px;
}

.error-message-2 {
  width: 90%;
  border-style: solid;
  border-width: 1px;
  border-color: #e4a2a2;
  border-radius: 5px;
  font-family: Rubik, sans-serif;
  /* font-family: Montserrat, sans-serif; */
}

.form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.success {
  width: 90%;
  border-radius: 10px;
  background-color: #0fc069;
}

.image-6 {
  max-width: 20%;
}

.text-block-2 {
  margin-top: 26px;
  color: #fff;
}

.image-7 {
  height: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}

.description-text {
  margin-top: 30px;
  width: 500px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  font-size: 19px;
  font-weight: 400;
  /* font-weight: 600; */
  text-align: center;
}

.description-text.slider {
  width: 80%;
  height: 100%;
  padding-top: 40px;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  color: #000;
  font-weight: 500;
  text-align: left;
}

.section-6 {
  height: 100vh;
  background-color: #f6ce04;
  background-image: url('../img/images/Square.png');
  background-position: 0px 0px;
  background-size: auto;
}

.icon-2 {
  padding-left: 0px;
  border-radius: 20px;
  background-color: red;
}

.icon-3 {
  border-radius: 20px;
  background-color: red;
}

.extra {
  width: 100%;
}

.paragraph-title {
  width: 80%;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  text-shadow: 1px 1px 6px #000;
}

.mask {
  background-color: transparent;
}

.slider-3 {
  display: block;
  height: auto;
  background-color: transparent;
}

.image-8 {
  display: inline-block;
  max-height: 461px;
  margin-top: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  -o-object-fit: fill;
  object-fit: fill;
}

.column-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.column-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.image-9 {
  max-height: 100%;
  max-width: 44%;
  margin-top: 10px;
}

.div-block-12 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block-14 {
  width: 50%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: center;
}

.div-block-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: center;
}

.div-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-block-3 {
  font-family: zpmm, sans-serif;
  color: #000;
  font-size: 24px;
}

.text-block-4 {
  padding-left: 9px;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  color: #000;
  font-size: 17px;
  font-weight: 700;
}

.slider-text {
  margin-top: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  color: #104563;
  /* text-align: left; */
}

.how-to {
  background-color: transparent;
}

.slide-nav-2 {
  left: 11%;
  top: auto;
  right: 0%;
  bottom: 0%;
}

.link {
  text-decoration: underline;
}

.link-2 {
  color: #000;
  text-decoration: underline;
}

.grid {
  margin-top: 51px;
  margin-bottom: 40px;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  grid-column-gap: 11px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.div-offer {
  height: 140px;
  border-radius: 6px;
  background-image: url('../img/images/Mask-Group.png');
  background-position: 50% 50%;
  background-size: cover;
}

.div-offer.pet {
  background-image: url('../img/images/Mask-Group.png');
}

.div-offer.petplant {
  background-image: url('../img/images/offer2.png');
}

.div-offer.goods {
  background-image: url('../img/images/offer3.png');
}

.div-offer.largeitems {
  background-image: url('../img/images/offer4.png');
}

.div-offer.movinghouse {
  background-image: url('../img/images/offer5.png');
}

.div-offer.smallitems {
  background-image: url('../img/images/offer6.png');
}

.title-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  color: #fff;
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  text-shadow: 1px 1px 6px #000;
}

.grid-2 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-2.features.extra {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-tapaw-unique {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 2px 7px 1px #dee7fd;
}

.div-tapaw-unique:hover {
  box-shadow: 0 2px 4px 2px #b3c4f3;
}

.div-tapaw-unique.extra {
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.paragraph-3 {
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  text-align: center;
}

.section-7 {
  margin-bottom: 62px;
}

.faq-plus-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-h1 {
  text-align: center;
}

.pricing-h1.faq-h2 {
  margin-bottom: 20px;
}

.faq-plus-l {
  position: absolute;
  width: 5px;
  height: 100%;
  border-radius: 3px;
  background-color: #ffcd3d;
}

.faq-q-text {
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  color: #285671;
  line-height: 32px;
  text-transform: uppercase;
}

.faq-plus {
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background-color: #ffcd3d;
}

.faq-question {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  font-weight: 800;
  cursor: pointer;
}

.container-2 {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 5vw;
  padding-left: 5vw;
}

.container-2.faq-container {
  max-width: 900px;
  padding-top: 20px;
  padding-bottom: 80px;
  border-radius: 13px;
  background-color: hsla(0, 0%, 100%, 0.5);
}

.link-3 {
  color: #2aa8ff;
  font-weight: 600;
}

.faq-answer-text {
  margin-bottom: 30px;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  font-size: 18px;
  /* font-size: 16px; */
  line-height: 26px;
}

.faq-answer {
  overflow: hidden;
  padding-left: 45px;
  border-bottom: 1px solid rgba(45, 62, 80, 0.12);
}

.faq-answer.last-faq-answer {
  border-bottom-width: 0px;
}

.div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-18 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.heading-9 {
  margin-bottom: 20px;
  font-size: 28px;
}

.select-field {
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  border-radius: 5px;
  background-color: #fff;
  font-family: Rubik, sans-serif;
  /* font-family: Montserrat, sans-serif; */
}

.div-block-19 {
  width: 90%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 32px;
  padding-bottom: 73px;
  padding-left: 19px;
  border-radius: 14px;
  background-color: rgba(46, 110, 204, 0.51);
}

.div-block-19.user {
  padding-left: 56px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-19.driver {
  display: none;
  overflow: visible;
  background-color: rgba(204, 144, 46, 0.27);
}

.div-block-19.vendor {
  margin-top: 402px;
  background-color: rgba(94, 94, 94, 0.51);
}

.image-10 {
  width: 100%;
  height: 160px;
  max-width: none;
  -o-object-fit: cover;
  object-fit: cover;
}

.div-block-20 {
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
}

.image-11 {
  -o-object-fit: fill;
  object-fit: fill;
}

.div-block-21 {
  overflow: hidden;
  width: 300px;
  height: 300px;
  background-image: url('../img/images/6-photo.f6de640f.jpg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
  -o-object-fit: fill;
  object-fit: fill;
}

.image-12 {
  -o-object-fit: fill;
  object-fit: fill;
}

.heading-10 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
}

.heading-10.connect {
  width: 100%;
}

.paragraph-4 {
  width: 600px;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  color: #fff;
  font-size: 19px;
  line-height: 32px;
  text-align: center;
}

.grid-3 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-13 {
  height: 30%;
}

.section-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0a2a41;
}

.div-block-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0a2a41;
}

.image-14 {
  display: inline-block;
  height: 80%;
}

.section-9 {
  height: 50vh;
}

.section-10 {
  padding-top: 89px;
}

.div-block-23 {
  width: 90%;
  margin-bottom: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #ffb702;
  border-radius: 9px;
  background-color: #ffefa9;
}

.paragraph-5 {
  padding: 10px 10px 10px 20px;
  font-family: Rubik, sans-serif;
  /* font-family: Montserrat, sans-serif; */
}

.heading-11 {
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 20px;
}

.dropdown-toggle {
  overflow: visible;
  width: 100%;
  height: 39px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #cdcdcd;
  border-radius: 5px;
}

.columns-3 {
  width: 90%;
}

.text-block-5 {
  font-family: Rubik, sans-serif;
  /* font-family: Montserrat, sans-serif; */
}

.text-block-6 {
  margin-top: 20px;
  margin-bottom: 20px;
  /* font-family: Montserrat, sans-serif; */
  font-family: Rubik, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.youtube {
  display: block;
  width: 80%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.container-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: left;
}

.text-block-7 {
  padding-right: 10px;
  padding-left: 10px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0 6px #000;
}

.grid-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.image-15 {
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.image-16 {
  height: 100px;
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -o-object-fit: cover;
  object-fit: cover;
}

.image-17 {
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.image-18 {
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 500px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 17px;
  background-image: url('../img/images/tapaw-main.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.image-19 {
  max-width: 50%;
}

.text-block-8 {
  font-family: Rubik, sans-serif;
  /* font-family: Montserrat, sans-serif; */
}

@media screen and (min-width: 1440px) {
  .footer-add-space {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1920px) {
  .button {
    width: 300px;
    height: 100px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .heading {
    width: 500px;
    font-size: 34px;
    line-height: 72px;
  }

  .div-block-2 {
    z-index: 1001;
  }

  .section-2 {
    display: block;
  }

  .columns {
    width: 100%;
  }

  .column {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-2 {
    margin-right: auto;
    margin-left: auto;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .image-3 {
    max-width: 24%;
    min-width: 110px;
  }

  .header {
    margin-top: 8em;
  }

  .slider-2 {
    margin-bottom: 8em;
  }

  .columns-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .div-block-9 {
    display: block;
    height: 100%;
    background-color: #fff;
  }

  .form {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .field-label {
    margin-left: 50px;
  }

  .form-2 {
    width: 100%;
    height: 100%;
    max-height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .column-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .column-4 {
    height: 90%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .error-message {
    width: 90%;
    font-family: Rubik, sans-serif;
    /* font-family: Montserrat, sans-serif; */
  }

  .error-message-2 {
    border-style: solid;
    border-width: 1px;
    border-color: #c57575;
    border-radius: 5px;
  }

  .form-block {
    height: 100%;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .success {
    border-style: solid;
    border-width: 2px;
    border-color: #bce1e4;
    border-radius: 10px;
    background-color: #0fc069;
  }

  .image-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .image-16 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .image-17 {
    max-width: 20%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .image-18 {
    max-width: 20%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .div-block-24 {
    width: 100%;
    height: 500px;
    max-height: 100%;
    max-width: 100%;
    background-image: url('../img/images/tapaw-main.jpg');
    background-position: 50% 0%;
    background-size: cover;
  }
}

@media screen and (max-width: 991px) {
  .faq-question {
    font-size: 20px;
  }

  h1 {
    margin-left: 61px;
  }

  .container {
    max-width: 100%;
  }

  .section {
    background-position: 0% 50%;
  }

  .button {
    /* margin-left: 61px; */
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .button.registration {
    margin-left: 0px;
  }

  .heading {
    width: 400px;
  }

  .div-block-2 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .div-block-3 {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .image-2 {
    padding-bottom: 20px;
  }

  .heading-5 {
    text-align: center;
  }

  .image-3 {
    max-width: 148px;
  }

  .nav-menu {
    background-color: #fff;
  }

  .nav-button {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .nav-button.sign-in {
    background-color: #d4d4d4;
  }

  .nav-link:hover {
    border-radius: 11px;
    background-color: #f99d1b;
  }

  .menu-button {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .icon {
    font-size: 40px;
  }

  .header {
    z-index: 0;
    margin-top: 8em;
    margin-bottom: 4em;
  }

  .credentials {
    margin-top: 4em;
    padding-bottom: 6em;
  }

  .slider-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .div-block-5 {
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-align-content: start;
    -ms-flex-line-pack: start;
    align-content: start;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .div-tapaw {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-tapaw.fourth {
    background-size: cover;
  }

  .footer {
    padding-right: 20px;
    padding-left: 20px;
  }

  .footer-flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-image {
    width: 40%;
    height: 80%;
  }

  .div-block-6 {
    margin-top: 79px;
  }

  .div-block-7 {
    margin-bottom: 35px;
    padding-right: 60px;
    padding-left: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-8 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .columns-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .div-block-9 {
    margin-left: 366px;
    padding-left: 0px;
  }

  .form {
    border-radius: 5px;
  }

  .form-2 {
    margin-left: 0px;
  }

  .column-3 {
    display: none;
  }

  .column-4 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .slide-nav-2 {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
  }

  .grid-2 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .heading-10.connect {
    margin-left: 0px;
  }

  .image-17 {
    max-width: 20%;
  }

  .image-18 {
    max-width: 10%;
  }
}

@media screen and (max-width: 767px) {
  .faq-question {
    font-size: 15px;
  }

  h1 {
    margin-left: 20px;
  }

  .container {
    max-width: 100%;
  }

  .container.navbar {
    padding-top: 0px;
  }

  .section {
    background-position: 30% 50%;
  }

  .button {
    /* margin-left: 20px; */
  }

  .heading {
    width: 326px;
  }

  .div-block-3 {
    width: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .heading-2 {
    margin-top: 0px;
    font-size: 13px;
  }

  .nav-link {
    margin-top: 10px;
  }

  .icon {
    max-height: 70px;
    max-width: 100%;
    -o-object-fit: scale-down;
    object-fit: scale-down;
  }

  .header {
    margin-top: 4em;
  }

  .div-block-5 {
    margin-right: 10px;
    padding-left: 10px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .footer {
    padding: 40px 20px;
  }

  .footer-image {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .div-block-9 {
    margin-left: 0px;
  }

  .form-2 {
    margin-left: 0px;
  }

  .description-text.slider {
    padding-top: 13px;
    text-align: center;
  }

  .image-9 {
    max-height: 100%;
    max-width: 100%;
    margin-top: 355px;
  }

  .div-block-13 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-14 {
    width: 36%;
    margin-top: -330px;
    padding-top: 0px;
  }

  .div-block-15 {
    width: 80%;
  }

  .div-block-16 {
    margin-top: 9px;
  }

  .slider-text {
    width: 100%;
    margin-top: 33px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  .slide-nav-2 {
    display: none;
  }

  .animated-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .pricing-h1 {
    font-size: 34px;
    line-height: 44px;
  }

  .heading-10.connect {
    font-size: 34px;
  }

  .paragraph-4 {
    width: 80%;
  }

  .grid-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-15 {
    max-width: 115em;
  }

  .image-16 {
    width: 200px;
    max-width: 200em;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .image-17 {
    width: 200px;
    max-width: 114em;
  }

  .image-18 {
    width: 200px;
    max-width: 57rem;
  }

  .image-19 {
    max-width: 80%;
  }
}

@media screen and (max-width: 479px) {
  .faq-question {
    font-size: 10px;
  }

  h1 {
    width: 250px;
  }

  .section {
    background-position: 31% 50%;
  }

  .div-block {
    padding-right: 20px;
  }

  .button {
    width: 100%;
    margin-left: 0px;
  }

  .heading {
    width: auto;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .div-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    grid-auto-flow: row dense;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .div-block-3 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .slide.second {
    background-position: 50% 50%, 50% 50%;
  }

  .slide.first {
    background-position: 50% 50%;
    background-repeat: repeat;
    background-attachment: scroll;
  }

  .slide.fourth {
    background-position: 50% 50%;
  }

  .list-feature {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-feature {
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .text-block {
    text-align: center;
  }

  .heading-3 {
    text-align: center;
  }

  .heading-5 {
    font-size: 22px;
    text-align: center;
  }

  .heading-6 {
    font-size: 5em;
  }

  .text-f-15 {
    font-size: 1.4em;
  }

  .header {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  .text-f-13 {
    font-size: 1.2em;
  }

  .text-f-13.all-caps.light-grey-font {
    font-size: 1.2em;
  }

  .heading-7 {
    font-size: 3.25em;
  }

  .credentials {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-4 {
    min-height: 90vh;
  }

  .carousel-arrows {
    margin-top: 2.5em;
  }

  .text-f-14 {
    font-size: 1.4em;
  }

  .credential.top-margin {
    margin-top: 2em;
  }

  .carousel-arrow-w.arrow-next {
    margin-right: 1em;
  }

  .carousel-arrow-w.arrow-previous {
    margin-left: 1em;
  }

  .div-block-5 {
    grid-auto-flow: row;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .footer {
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
  }

  .footer-flex-container {
    margin-top: -45px;
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .footer-logo-link {
    height: 60px;
  }

  .footer-image {
    width: 80%;
    height: 140%;
  }

  .footer-heading {
    margin-top: 20px;
  }

  .div-block-6 {
    margin-top: 20px;
  }

  .div-block-7 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .description-text {
    font-size: 11px;
  }

  .div-block-14 {
    width: 44%;
  }

  .right-arrow {
    top: -333px;
  }

  .left-arrow {
    top: -333px;
  }

  .grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .title-image {
    font-size: 15px;
  }

  .grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .faq-q-text {
    font-size: 16px;
    line-height: 18px;
  }

  .heading-10.connect {
    font-size: 22px;
  }

  .paragraph-4 {
    width: 80%;
    font-size: 15px;
    line-height: 22px;
  }

  .grid-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-15 {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .image-16 {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .image-17 {
    width: 150px;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .image-18 {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .image-19 {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  #w-node-b0e8775a-c587-12fa-d596-45b9b5468459-b5a66672 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_58be8472-b812-1f13-6f3d-50e2ed76fecc-b5a66672 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@media screen and (max-width: 767px) {
  #w-node-b0e8775a-c587-12fa-d596-45b9b5468459-b5a66672 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_58be8472-b812-1f13-6f3d-50e2ed76fecc-b5a66672 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_58be8472-b812-1f13-6f3d-50e2ed76fecc-b5a66672 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@font-face {
  font-family: 'zpmm';
  src: url('../fonts/CombinumeralsBold-Zpmm.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.div-new {
  /* margin-top: 20px; */
  margin-bottom: 20px;
  margin-left: 60px;
  margin-right: 60px;
  border-radius: 9px;
  background-color: #f3f3f3;
  box-shadow: 1px 1px 3px 0 #9ea1a5;
}

.grid-cta {
  grid-column-gap: 7px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-cta {
  margin: 5px;
  padding: 7px;
  border-radius: 5px;
}

.div-cta:hover {
  border-radius: 9px;
  /* height: 150px; */
  background-color: #ffe013;
}

.image-2-google {
  height: 120px;
}

.image-3-apple {
  height: 120px;
}

.columns-cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.column-cta {
  text-align: right;
}

.column-2-cta {
  text-align: left;
}

.text-block-cta {
  margin-bottom: 7px;
  font-family: Rubik, sans-serif;
  color: #224561;
  font-weight: 600;
  text-align: center;
}

.image-cta {
  padding: 0px 24px;
}

@media screen and (max-width: 767px) {
  .div-new {
    margin-right: 14px;
    margin-left: 14px;
  }

  .image-cta {
    padding-right: 2px;
    padding-left: 2px;
  }

  .text-block-cta {
    font-size: 9px;
  }

  .image-2-google {
    height: auto;
  }

  .image-3-apple {
    height: auto;
  }
}

@media screen and (max-width: 479px) {
  .grid-cta {
    grid-template-columns: 1fr 1fr;
  }

  .div-new {
    margin-right: 16px;
    margin-left: 16px;
  }

  .grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .image-cta {
    height: 100px;
    padding: 7px;
  }

  .text-block-cta {
    font-size: 13px;
  }

  .div-cta {
    margin-top: -4px;
    margin-bottom: -4px;
  }

  .image-2-google {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 479px) {
  #w-node-df90338c-3fa0-6c41-3852-c8d6595c3cc5-1b204b53 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3e5cfb0a-985c-5110-e5b9-4d4d27eb2a04-1b204b53 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_4e5fe6a0-1371-df9a-49be-d4a8f47f2dd3-1b204b53 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-aa43203e-059f-f063-51b4-23d24fc6d2ec-1b204b53 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_086ff49c-4e8d-d7ad-7525-50baf85c3f30-1b204b53 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: center;
    justify-self: center;
  }
}
